import { computed, type ComputedRef } from 'vue'
import { DataProductTypeEnum } from '@prisma/client'
import { entityTypeInfo } from '../entityTypeMapping'
import { useI18n } from 'vue-i18n'
import type { SelectorItem } from '../selector'
import { useDetailAttributes } from './detail-attributes'

export const useDataProductTypes = () => {
    const { t } = useI18n()

    return Object.values(DataProductTypeEnum).map((type) => {
        return {
            id: type,
            label: t(`entityTypes.${type}`),
            icon: entityTypeInfo[type].icon,
        } as const
    })
}

export const useDataProductsEnabledForCustomProperty = (detailAttributeId?: ComputedRef<string | undefined>) => {
    const { t } = useI18n()
    const { dataProductTypesEnabledForDetailAttribute } = useDetailAttributes()

    const productsEnabledForCustomProperty = dataProductTypesEnabledForDetailAttribute(detailAttributeId)

    const enabledProductsItems = computed(() => {
        if (!detailAttributeId?.value) return []
        return Object.values(DataProductTypeEnum).map((type) => {
            return {
                id: type,
                label: t(`entityTypes.${type}`),
                icon: entityTypeInfo[type].icon,
                disabled: !productsEnabledForCustomProperty.value.includes(type),
            } as SelectorItem
        })
    })

    return { enabledProductsItems, productsEnabledForCustomProperty }
}
